import React, { useState } from "react";
import { Box } from "@mui/material";
import Sidebar from "./Sidebar";
import { useAuth } from "../../../context/AuthContext";
import { Helmet } from "react-helmet";

const DashboardLayout = ({ children }) => {
  const { user } = useAuth();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  const handleSidebarCollapse = (collapsed) => {
    setIsSidebarCollapsed(collapsed);
  };

  return (
    <React.Fragment>
      <Helmet>
        {user.role && user.tawkId && (
          <script type="text/javascript">
            {`
              var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
              (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                s1.src='https://embed.tawk.to/${user.tawkId}';
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
              })();
            `}
          </script>
        )}
      </Helmet>
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Sidebar onClientChange={() => {}} onCollapse={handleSidebarCollapse} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            bgcolor: "#f4f5f7",
            width: {
              sm: `calc(100% - ${isSidebarCollapsed ? "80px" : "260px"})`,
            },

            transition: "margin-left 0.2s ease-in-out, width 0.2s ease-in-out",
            minHeight: "100vh",
            p: 3,
          }}
        >
          {children}
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default DashboardLayout;
