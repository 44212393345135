import React, { useState, useEffect } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  Typography,
  Divider,
  Select,
  FormControl,
  InputLabel,
  ListItemButton,
  Collapse,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import PeopleIcon from "@mui/icons-material/People";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import IntegrationInstructionsIcon from "@mui/icons-material/IntegrationInstructions";
import SmartToyIcon from "@mui/icons-material/SmartToy";
// import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import NotesIcon from "@mui/icons-material/Notes";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import TimerIcon from "@mui/icons-material/Timer";
import MonitorIcon from "@mui/icons-material/Monitor";
import SprintIcon from "@mui/icons-material/DirectionsRun";
import BuildIcon from "@mui/icons-material/Build";
import ImageIcon from "@mui/icons-material/Image";
import BrushIcon from "@mui/icons-material/Brush";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import CodeIcon from "@mui/icons-material/Code";
import QrCodeIcon from "@mui/icons-material/QrCode";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import PhotoFilterIcon from "@mui/icons-material/PhotoFilter";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import MarkdownIcon from "@mui/icons-material/Code";
import DataObjectIcon from "@mui/icons-material/DataObject";
import CompressIcon from "@mui/icons-material/Compress";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import GestureIcon from "@mui/icons-material/Gesture";
import UpdateIcon from "@mui/icons-material/Update";
import WebIcon from "@mui/icons-material/Web";
import CloudIcon from "@mui/icons-material/Cloud";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";
import { authAPI } from "../../../services/api";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const Sidebar = ({ onClientChange, onCollapse }) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState("all");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openUsers, setOpenUsers] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    if (user?.role === "admin") {
      loadClients();
    }
  }, [user]);

  useEffect(() => {
    if (onCollapse) {
      onCollapse(isCollapsed);
    }
  }, [isCollapsed, onCollapse]);

  const loadClients = async () => {
    try {
      const response = await authAPI.getClients();
      setClients(response.data.clients || []);
    } catch (error) {
      console.error("Failed to load clients:", error);
    }
  };

  const handleClientChange = (event) => {
    const clientId = event.target.value;
    setSelectedClient(clientId);
    onClientChange(clientId);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    handleMenuClose();
    logout();
  };

  const getUserInitial = () => {
    if (user?.name) return user.name.charAt(0).toUpperCase();
    if (user?.email) return user.email.charAt(0).toUpperCase();
    return "U";
  };

  const getDisplayName = () => {
    if (user?.name) return user.name;
    if (user?.email) return user.email.split("@")[0];
    return "User";
  };

  const isActive = (path) => {
    if (
      path === "/dashboard/kanban" &&
      (location.pathname === "/dashboard/kanban" ||
        location.pathname === "/dashboard/" ||
        location.pathname === "/dashboard")
    ) {
      return true;
    }
    return location.pathname === path;
  };

  const tools = [
    {
      name: "SVG Editor",
      icon: <BrushIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/svg-editor",
      description: "Create and edit SVG graphics",
    },
    {
      name: "QR Generator",
      icon: <QrCodeIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/qr-generator",
      description: "Generate QR codes",
    },
    {
      name: "Barcode Generator",
      icon: <ViewModuleIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/barcode-generator",
      description: "Generate various types of barcodes",
    },
    {
      name: "PDF Tools",
      icon: <PictureAsPdfIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/pdf-tools",
      description: "Merge, split and compress PDF files",
    },
    {
      name: "Image Effects",
      icon: <PhotoFilterIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/image-effects",
      description: "Apply filters and effects to images",
    },
    {
      name: "Image Converter",
      icon: <ImageIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/image-converter",
      description: "Convert images between formats",
    },
    {
      name: "Color Picker",
      icon: <ColorLensIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/color-picker",
      description: "Advanced color selection tool",
    },
    {
      name: "Code Formatter",
      icon: <CodeIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/code-formatter",
      description: "Format and beautify code",
    },
    {
      name: "Text Editor",
      icon: <TextFieldsIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/text-editor",
      description: "Rich text editor with formatting options",
    },
    {
      name: "Markdown Editor",
      icon: <MarkdownIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/markdown-editor",
      description: "Write and preview Markdown",
    },
    {
      name: "JSON Formatter",
      icon: <DataObjectIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/json-formatter",
      description: "Format and validate JSON data",
    },
    {
      name: "Image Compressor",
      icon: <CompressIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/image-compressor",
      description: "Compress images without quality loss",
    },
    {
      name: "File Converter",
      icon: <SwapHorizIcon sx={{ color: "white" }} />,
      path: "/dashboard/tools/file-converter",
      description: "Convert files between formats",
    },
  ];

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: isCollapsed ? 80 : 260,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: isCollapsed ? 80 : 260,
          boxSizing: "border-box",
          backgroundColor: "#1E1E1E",
          color: "white",
          transition: "width 0.3s ease-in-out",
          overflowX: "hidden",
          position: "fixed",
          height: "100%",
          border: "none",
        },
      }}
    >
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: isCollapsed ? "center" : "space-between",
        }}
      >
        {!isCollapsed && (
          <>
            <img
              src="/img/Javed-Avatar.svg"
              alt="XD Logo"
              style={{ height: 40 }}
            />
            <Typography variant="h6" component="div">
              XD STUDIOS
            </Typography>
          </>
        )}
        <IconButton
          onClick={() => setIsCollapsed(!isCollapsed)}
          sx={{ color: "white" }}
        >
          {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </Box>

      <Divider sx={{ bgcolor: "rgba(255,255,255,0.1)" }} />

      <List>
        {/* <ListItemButton
          onClick={() => navigate("/dashboard")}
          selected={isActive("/dashboard")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <DashboardIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Dashboard" />}
        </ListItemButton> */}

        <ListItemButton
          onClick={() => navigate("/dashboard/kanban")}
          selected={isActive("/dashboard/kanban")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <ViewKanbanIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Roadmap" />}
        </ListItemButton>

        {/* <ListItemButton
          onClick={() => navigate("/dashboard/calendar")}
          selected={isActive("/dashboard/calendar")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <CalendarMonthIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Calendar" />}
        </ListItemButton> */}

        <ListItemButton
          onClick={() => navigate("/dashboard/documents")}
          selected={isActive("/dashboard/documents")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <NotesIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Docs" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/affine")}
          selected={isActive("/dashboard/affine")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <DataObjectIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Affine" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/jira")}
          selected={isActive("/dashboard/jira")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <BugReportIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Jira" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/dropbox")}
          selected={isActive("/dashboard/dropbox")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <CloudIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Dropbox" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/whiteboard")}
          selected={isActive("/dashboard/whiteboard")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <GestureIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Board" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/mocks")}
          selected={isActive("/dashboard/mocks")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <WebIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Mocks" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/updates")}
          selected={isActive("/dashboard/updates")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <UpdateIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Drops" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/llm")}
          selected={isActive("/dashboard/llm")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <SmartToyIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="XD LLM" />}
        </ListItemButton>

        <ListItemButton
          onClick={() => navigate("/dashboard/tools")}
          selected={isActive("/dashboard/tools")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <BuildIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Toolkit" />}
        </ListItemButton>
        {/* <ListItemButton
          onClick={() => navigate("/dashboard/monitoring")}
          selected={isActive("/dashboard/monitoring")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <MonitorIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="Status" />}
        </ListItemButton> */}

        {(user?.role === "admin" || user?.role === "client") && (
          <ListItemButton
            onClick={() => navigate("/dashboard/integration")}
            selected={isActive("/dashboard/integration")}
            sx={{
              minHeight: 48,
              justifyContent: isCollapsed ? "center" : "initial",
              px: 2.5,
              "&.Mui-selected": {
                bgcolor: "rgba(255, 255, 255, 0.08)",
              },
              "&.Mui-selected:hover": {
                bgcolor: "rgba(255, 255, 255, 0.12)",
              },
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.05)",
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isCollapsed ? 0 : 3,
                justifyContent: "center",
              }}
            >
              <IntegrationInstructionsIcon sx={{ color: "white" }} />
            </ListItemIcon>
            {!isCollapsed && <ListItemText primary="Integrations" />}
          </ListItemButton>
        )}

        {user?.role === "admin" && (
          <>
            {/* <ListItemButton
              onClick={() => navigate("/dashboard/sprints")}
              selected={isActive("/dashboard/sprints")}
              sx={{
                minHeight: 48,
                justifyContent: isCollapsed ? "center" : "initial",
                px: 2.5,
                "&.Mui-selected": {
                  bgcolor: "rgba(255, 255, 255, 0.08)",
                },
                "&.Mui-selected:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.12)",
                },
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.05)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isCollapsed ? 0 : 3,
                  justifyContent: "center",
                }}
              >
                <SprintIcon sx={{ color: "white" }} />
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Sprints" />}
            </ListItemButton> */}

            <ListItemButton
              onClick={() => navigate("/users/manage")}
              selected={isActive("/users/manage")}
              sx={{
                minHeight: 48,
                justifyContent: isCollapsed ? "center" : "initial",
                px: 2.5,
                "&:hover": {
                  bgcolor: "rgba(255, 255, 255, 0.05)",
                },
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: isCollapsed ? 0 : 3,
                  justifyContent: "center",
                }}
              >
                <PeopleIcon sx={{ color: "white" }} />
              </ListItemIcon>
              {!isCollapsed && <ListItemText primary="Users" />}
            </ListItemButton>
          </>
        )}
      </List>

      {user?.role === "admin" && (
        <Box sx={{ p: 2 }}>
          <FormControl fullWidth variant="outlined" size="small">
            <InputLabel sx={{ color: "white" }}>View Client</InputLabel>
            <Select
              value={selectedClient}
              onChange={handleClientChange}
              label="View Client"
              sx={{
                color: "white",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255,255,255,0.3)",
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255,255,255,0.5)",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
              }}
            >
              <MenuItem value="all">All Clients</MenuItem>
              {clients.map((client) => (
                <MenuItem key={client._id} value={client._id}>
                  {client.name || client.email.split("@")[0]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}

      <List>
        {/* <ListItemButton
          onClick={() => navigate("/dashboard/clocks")}
          selected={isActive("/dashboard/clocks")}
          sx={{
            minHeight: 48,
            justifyContent: isCollapsed ? "center" : "initial",
            px: 2.5,
            "&.Mui-selected": {
              bgcolor: "rgba(255, 255, 255, 0.08)",
            },
            "&.Mui-selected:hover": {
              bgcolor: "rgba(255, 255, 255, 0.12)",
            },
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
            },
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: isCollapsed ? 0 : 3,
              justifyContent: "center",
            }}
          >
            <TimerIcon sx={{ color: "white" }} />
          </ListItemIcon>
          {!isCollapsed && <ListItemText primary="World Clocks" />}
        </ListItemButton> */}
      </List>

      <Box sx={{ mt: "auto", p: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            cursor: "pointer",
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.05)",
              borderRadius: "8px",
            },
            p: 1,
          }}
          onClick={handleAvatarClick}
        >
          <Avatar sx={{ bgcolor: "#0052CC" }}>{getUserInitial()}</Avatar>
          <Box>
            <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
              {getDisplayName()}
            </Typography>
            <Typography
              variant="caption"
              sx={{
                color: "rgba(255,255,255,0.7)",
                textTransform: "capitalize",
              }}
            >
              {user?.role}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </Drawer>
  );
};

export default Sidebar;
