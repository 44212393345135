import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api";

// Create axios instance with default config
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

// Add request interceptor for authentication and logging
api.interceptors.request.use(
  (config) => {
    // Add auth token if available
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    console.log("Making request:", {
      url: config.url,
      method: config.method,
      headers: config.headers,
      data: config.data,
    });
    return config;
  },
  (error) => {
    console.error("Request error:", error);
    return Promise.reject(error);
  }
);

// Add response interceptor for logging
api.interceptors.response.use(
  (response) => {
    console.log("Response received:", {
      status: response.status,
      data: response.data,
    });
    return response;
  },
  (error) => {
    console.error("API Error:", {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
    });
    return Promise.reject(error);
  }
);

// Export API methods
export const authAPI = {
  signup: async (userData) => {
    try {
      const response = await api.post("/auth/signup", userData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.error("Signup failed:", error);
      return {
        success: false,
        error: error.response?.data?.error || "Signup failed",
      };
    }
  },

  login: async (credentials) => {
    try {
      const response = await api.post("/auth/login", credentials);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.error("Login failed:", error);
      return {
        success: false,
        error: error.response?.data?.error || "Login failed",
      };
    }
  },

  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
  },

  getCurrentUser: async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("No auth token found");
      }
      const response = await api.get("/auth/me");
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      console.error("Get current user failed:", error);
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get current user",
      };
    }
  },

  createUser: async (userData) => {
    try {
      const response = await api.post("/auth/users", userData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create user",
      };
    }
  },

  getUsers: async () => {
    try {
      const response = await api.get("/auth/users");
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get users",
      };
    }
  },

  getClients: async () => {
    try {
      const response = await api.get("/auth/clients");
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get clients",
      };
    }
  },

  deleteUser: async (userId) => {
    try {
      const response = await api.delete(`/auth/users/${userId}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete user",
      };
    }
  },

  updateUser: async (userId, userData) => {
    try {
      const response = await api.patch(`/auth/users/${userId}`, userData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update user",
      };
    }
  },

  updateUserPassword: async (userId, data) => {
    try {
      const response = await api.patch(`/auth/users/${userId}/password`, data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update password",
      };
    }
  },
};

export const taskAPI = {
  getTasks: async (clientId) => {
    try {
      const response = await api.get("/tasks", { params: { clientId } });
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get tasks",
      };
    }
  },

  createTask: async (taskData) => {
    try {
      const response = await api.post("/tasks", taskData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create task",
      };
    }
  },

  updateTask: async (taskId, updates) => {
    try {
      const response = await api.patch(`/tasks/${taskId}`, updates);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update task",
      };
    }
  },

  deleteTask: async (taskId) => {
    try {
      const response = await api.delete(`/tasks/${taskId}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete task",
      };
    }
  },
};

export const integrationAPI = {
  getAll: async () => {
    try {
      const response = await api.get("/integrations");
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get integrations",
      };
    }
  },

  create: async (data) => {
    try {
      const response = await api.post("/integrations", data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create integration",
      };
    }
  },

  update: async (id, data) => {
    try {
      const response = await api.put(`/integrations/${id}`, data);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update integration",
      };
    }
  },

  delete: async (id) => {
    try {
      const response = await api.delete(`/integrations/${id}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete integration",
      };
    }
  },

  getGoogleAuthStatus: async () => {
    try {
      const response = await fetch("/api/integration/google/status");
      const data = await response.json();
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.message };
    }
  },

  getGoogleAuthUrl: async () => {
    try {
      const response = await fetch("/api/integration/google/auth-url");
      const data = await response.json();
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.message };
    }
  },

  syncGoogleEvents: async () => {
    try {
      const response = await fetch("/api/integration/google/sync-events", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      return { success: true, data };
    } catch (error) {
      return { success: false, error: error.message };
    }
  },
};

// Events
export const createEvent = async (eventData) => {
  try {
    const response = await api.post("/events", eventData);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.error("Error creating event:", error);
    return {
      success: false,
      error: error.response?.data?.error || "Failed to create event",
    };
  }
};

export const getEvents = async () => {
  try {
    const response = await api.get("/events");
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.error("Error fetching events:", error);
    return {
      success: false,
      error: error.response?.data?.error || "Failed to fetch events",
    };
  }
};

export const updateEvent = async (eventId, eventData) => {
  try {
    const response = await api.patch(`/events/${eventId}`, eventData);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.error("Error updating event:", error);
    return {
      success: false,
      error: error.response?.data?.error || "Failed to update event",
    };
  }
};

export const deleteEvent = async (eventId) => {
  try {
    const response = await api.delete(`/events/${eventId}`);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.error("Error deleting event:", error);
    return {
      success: false,
      error: error.response?.data?.error || "Failed to delete event",
    };
  }
};

export const getParticipants = async (type) => {
  try {
    const response = await api.get(`/events/participants/${type}`);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.error("Error fetching participants:", error);
    return {
      success: false,
      error: error.response?.data?.error || "Failed to fetch participants",
    };
  }
};

// Notes
export const noteAPI = {
  createNote: async (noteData) => {
    try {
      const response = await api.post("/notes", noteData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create note",
      };
    }
  },

  getNotes: async () => {
    try {
      const response = await api.get("/notes");
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get notes",
      };
    }
  },

  getNote: async (noteId) => {
    try {
      const response = await api.get(`/notes/${noteId}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get note",
      };
    }
  },

  updateNote: async (noteId, noteData) => {
    try {
      const response = await api.patch(`/notes/${noteId}`, noteData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update note",
      };
    }
  },

  deleteNote: async (noteId) => {
    try {
      const response = await api.delete(`/notes/${noteId}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete note",
      };
    }
  },

  addBookmark: async (noteId, bookmarkData) => {
    try {
      const response = await api.post(
        `/notes/${noteId}/bookmarks`,
        bookmarkData
      );
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to add bookmark",
      };
    }
  },

  removeBookmark: async (noteId, bookmarkId) => {
    try {
      const response = await api.delete(
        `/notes/${noteId}/bookmarks/${bookmarkId}`
      );
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to remove bookmark",
      };
    }
  },

  searchNotes: async (query, tags) => {
    try {
      const params = new URLSearchParams();
      if (query) params.append("query", query);
      if (tags) params.append("tags", tags.join(","));

      const response = await api.get(`/notes/search?${params.toString()}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to search notes",
      };
    }
  },
};

// Monitoring (Uptime Kuma)
export const monitoringAPI = {
  getMonitors: async () => {
    try {
      const response = await api.get("/monitoring/monitors");
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get monitors",
      };
    }
  },

  addMonitor: async (monitorData) => {
    try {
      const response = await api.post("/monitoring/monitors", monitorData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to add monitor",
      };
    }
  },

  updateMonitor: async (monitorId, monitorData) => {
    try {
      const response = await api.patch(
        `/monitoring/monitors/${monitorId}`,
        monitorData
      );
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update monitor",
      };
    }
  },

  deleteMonitor: async (monitorId) => {
    try {
      const response = await api.delete(`/monitoring/monitors/${monitorId}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete monitor",
      };
    }
  },

  getMonitorStatus: async (monitorId) => {
    try {
      const response = await api.get(
        `/monitoring/monitors/${monitorId}/status`
      );
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get monitor status",
      };
    }
  },
};

export const sprintAPI = {
  getSprints: async () => {
    try {
      const response = await api.get("/sprints");
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to fetch sprints",
      };
    }
  },

  getActiveSprints: async () => {
    try {
      const response = await api.get("/sprints/active");
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to fetch active sprints",
      };
    }
  },

  createSprint: async (sprintData) => {
    try {
      const response = await api.post("/sprints", sprintData);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create sprint",
      };
    }
  },

  updateSprint: async (sprintId, sprintData) => {
    try {
      const response = await api.patch(`/sprints/${sprintId}`, sprintData);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update sprint",
      };
    }
  },

  deleteSprint: async (sprintId) => {
    try {
      const response = await api.delete(`/sprints/${sprintId}`);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete sprint",
      };
    }
  },

  addGoal: async (sprintId, goalData) => {
    try {
      const response = await api.post(`/sprints/${sprintId}/goals`, goalData);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to add goal",
      };
    }
  },

  updateGoalStatus: async (sprintId, goalId, status) => {
    try {
      const response = await api.patch(`/sprints/${sprintId}/goals/${goalId}`, {
        status,
      });
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update goal status",
      };
    }
  },

  addTask: async (sprintId, taskId) => {
    try {
      const response = await api.post(`/sprints/${sprintId}/tasks`, { taskId });
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to add task to sprint",
      };
    }
  },

  removeTask: async (sprintId, taskId) => {
    try {
      const response = await api.delete(`/sprints/${sprintId}/tasks/${taskId}`);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error:
          error.response?.data?.error || "Failed to remove task from sprint",
      };
    }
  },
};

export const clockAPI = {
  getClocks: async () => {
    try {
      const response = await api.get("/clocks");
      return {
        success: true,
        data: response.data.data || response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to fetch clocks",
      };
    }
  },

  createClock: async (clockData) => {
    try {
      const response = await api.post("/clocks", clockData);
      return {
        success: true,
        data: response.data.data || response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create clock",
      };
    }
  },

  updateClock: async (clockId, clockData) => {
    try {
      const response = await api.patch(`/clocks/${clockId}`, clockData);
      return {
        success: true,
        data: response.data.data || response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update clock",
      };
    }
  },

  deleteClock: async (clockId) => {
    try {
      const response = await api.delete(`/clocks/${clockId}`);
      return {
        success: true,
        data: response.data.data || response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete clock",
      };
    }
  },
};

export const folderAPI = {
  getFolders: async () => {
    try {
      const response = await api.get("/folders");
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to get folders",
      };
    }
  },

  createFolder: async (folderData) => {
    try {
      const response = await api.post("/folders", folderData);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create folder",
      };
    }
  },

  updateFolder: async (folderId, updates) => {
    try {
      const response = await api.patch(`/folders/${folderId}`, updates);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update folder",
      };
    }
  },

  deleteFolder: async (folderId) => {
    try {
      const response = await api.delete(`/folders/${folderId}`);
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete folder",
      };
    }
  },

  moveDocument: async (documentId, folderId) => {
    try {
      const response = await api.patch(
        `/folders/${folderId}/documents/${documentId}`
      );
      return {
        success: true,
        data: response.data.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to move document",
      };
    }
  },
};

export const jiraAPI = {
  getBoards: async () => {
    try {
      const response = await api.get("/jira");
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to fetch Jira boards",
      };
    }
  },

  createBoard: async (boardData) => {
    try {
      const response = await api.post("/jira", boardData);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create Jira board",
      };
    }
  },

  deleteBoard: async (boardId) => {
    try {
      const response = await api.delete(`/jira/${boardId}`);
      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete Jira board",
      };
    }
  },
};

// Dropbox API
export const dropboxAPI = {
  getFolders: async () => {
    try {
      const response = await api.get("/dropbox");
      return response.data;
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to fetch Dropbox folders",
      };
    }
  },

  getFolderDetails: async (id) => {
    if (!id) {
      return {
        success: false,
        error: "Invalid folder ID",
      };
    }
    try {
      const response = await api.get(`/dropbox/${id}`);
      return response.data;
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to fetch folder details",
      };
    }
  },

  createFolder: async (folderData) => {
    try {
      const response = await api.post("/dropbox", folderData);
      return response.data;
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to create folder",
      };
    }
  },

  updateFolder: async (id, folderData) => {
    try {
      const response = await api.put(`/dropbox/${id}`, folderData);
      return response.data;
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to update folder",
      };
    }
  },

  deleteFolder: async (id) => {
    try {
      const response = await api.delete(`/dropbox/${id}`);
      return response.data;
    } catch (error) {
      return {
        success: false,
        error: error.response?.data?.error || "Failed to delete folder",
      };
    }
  },
};

export default api;
