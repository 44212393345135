import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import ProtectedRoute from "./components/ProtectedRoute";
import DashboardLayout from "./components/Dashboard/layout/DashboardLayout";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Affine from "./components/Dashboard/Affine/Affine";
import JiraDashboard from "./components/Dashboard/Jira/JiraDashboard";

// Loading component
const LoadingFallback = () => (
  <div
    style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      fontSize: "1.2rem",
      color: "#37352f",
    }}
  >
    Loading...
  </div>
);

// Lazy loaded components
const HomePage = lazy(() => import("./pages/home/home"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const OriginalDashboard = lazy(
  () => import("./components/Dashboard/OrignalDashboard")
);
const AddUser = lazy(() => import("./pages/users/AddUser"));
const ManageUsers = lazy(() => import("./pages/users/ManageUsers"));
const Integrations = lazy(() => import("./pages/Integrations"));
const CalendarPage = lazy(() => import("./pages/Calendar"));
const NestedDoc = lazy(() => import("./components/Dashboard/Nested/NestedDoc"));
const Dropbox = lazy(() => import("./components/Dashboard/Dropbox/Dropbox"));
const MonitoringDashboard = lazy(
  () => import("./components/Dashboard/Monitoring/MonitoringDashboard")
);
const SprintDashboard = lazy(
  () => import("./components/Dashboard/Sprint/SprintDashboard")
);
const WhiteboardEditor = lazy(
  () => import("./components/Dashboard/Whiteboard/WhiteboardEditor")
);
const Updates = lazy(() => import("./components/Dashboard/Updates/Updates"));
const Mocks = lazy(() => import("./components/Dashboard/Mocks/mocks"));
const Soophie = lazy(() => import("./pages/home/Soophie"));

// Lazy loaded tools
const ToolsDashboard = lazy(
  () => import("./components/Dashboard/Tools/ToolsDashboard")
);
const SvgEditor = lazy(() => import("./components/Dashboard/Tools/SvgEditor"));
const ImageConverter = lazy(
  () => import("./components/Dashboard/Tools/ImageConverter")
);
const ColorPicker = lazy(
  () => import("./components/Dashboard/Tools/ColorPicker")
);
const CodeFormatter = lazy(
  () => import("./components/Dashboard/Tools/CodeFormatter")
);
const Clocks = lazy(() => import("./components/Dashboard/Clocks/Clocks"));
const QrGenerator = lazy(
  () => import("./components/Dashboard/Tools/QrGenerator")
);
const BarcodeGenerator = lazy(
  () => import("./components/Dashboard/Tools/BarcodeGenerator")
);
const PdfTools = lazy(() => import("./components/Dashboard/Tools/PdfTools"));
const ImageEffects = lazy(
  () => import("./components/Dashboard/Tools/ImageEffects")
);
const TextEditor = lazy(
  () => import("./components/Dashboard/Tools/TextEditor")
);
const MarkdownEditor = lazy(
  () => import("./components/Dashboard/Tools/MarkdownEditor")
);
const JsonFormatter = lazy(
  () => import("./components/Dashboard/Tools/JsonFormatter")
);
const ImageCompressor = lazy(
  () => import("./components/Dashboard/Tools/ImageCompressor")
);
const FileConverter = lazy(
  () => import("./components/Dashboard/Tools/FileConverter")
);
const LLMPage = lazy(() => import("./app/dashboard/llm/page"));
// Lazy loaded case studies
const CaseStudies = {
  CairCaseStudy: lazy(() => import("./pages/home/case-studies/cair")),
  SerpapiCaseStudy: lazy(() => import("./pages/home/case-studies/serpapi")),
  UrbanFoodyCaseStudy: lazy(
    () => import("./pages/home/case-studies/urban-foody")
  ),
  ClomrCaseStudy: lazy(() => import("./pages/home/case-studies/clomr")),
  LunchboxCaseStudy: lazy(() => import("./pages/home/case-studies/lunchbox")),
  VmwareCaseStudy: lazy(() => import("./pages/home/case-studies/vmware")),
  FeelingBlessedCaseStudy: lazy(
    () => import("./pages/home/case-studies/feeling-blessed")
  ),
  CrunchyrollCaseStudy: lazy(
    () => import("./pages/home/case-studies/crunchyroll")
  ),
  DockerCaseStudy: lazy(() => import("./pages/home/case-studies/docker")),
  LottieCaseStudy: lazy(() => import("./pages/home/case-studies/lottie")),
  VideoCaseStudy: lazy(() => import("./pages/home/case-studies/videos")),
  Deck: lazy(() => import("./pages/home/case-studies/deck")),
  CV: lazy(() => import("./pages/home/case-studies/cv")),
  About: lazy(() => import("./pages/home/about/About")),
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulation of any required initial data loading
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 300);

    return () => clearTimeout(timer);
  }, []);

  if (isLoading) {
    return <LoadingFallback />;
  }

  return (
    <AuthProvider>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />

          {/* Protected routes */}
          <Route
            path="/dashboard/*"
            element={
              <ProtectedRoute>
                <DashboardLayout>
                  <Suspense fallback={<LoadingFallback />}>
                    <Routes>
                      <Route path="/" element={<Dashboard />} />
                      <Route path="original" element={<OriginalDashboard />} />
                      <Route path="kanban" element={<Dashboard />} />
                      <Route path="affine" element={<Affine />} />
                      <Route path="jira" element={<JiraDashboard />} />
                      <Route path="dropbox" element={<Dropbox />} />
                      <Route path="documents" element={<NestedDoc />} />
                      <Route path="whiteboard" element={<WhiteboardEditor />} />
                      <Route path="updates" element={<Updates />} />
                      <Route path="mocks" element={<Mocks />} />
                      <Route path="integration" element={<Integrations />} />
                      <Route
                        path="monitoring"
                        element={<MonitoringDashboard />}
                      />
                      <Route path="sprints" element={<SprintDashboard />} />
                      <Route path="calendar" element={<CalendarPage />} />
                      <Route path="clocks" element={<Clocks />} />
                      <Route path="tools" element={<ToolsDashboard />} />
                      <Route path="llm" element={<LLMPage />} />

                      {/* Individual tool routes */}
                      <Route path="tools/svg-editor" element={<SvgEditor />} />
                      <Route
                        path="tools/image-converter"
                        element={<ImageConverter />}
                      />
                      <Route
                        path="tools/color-picker"
                        element={<ColorPicker />}
                      />
                      <Route
                        path="tools/code-formatter"
                        element={<CodeFormatter />}
                      />
                      <Route
                        path="tools/qr-generator"
                        element={<QrGenerator />}
                      />
                      <Route
                        path="tools/barcode-generator"
                        element={<BarcodeGenerator />}
                      />
                      <Route path="tools/pdf-tools" element={<PdfTools />} />
                      <Route
                        path="tools/image-effects"
                        element={<ImageEffects />}
                      />
                      <Route
                        path="tools/text-editor"
                        element={<TextEditor />}
                      />
                      <Route
                        path="tools/markdown-editor"
                        element={<MarkdownEditor />}
                      />
                      <Route
                        path="tools/json-formatter"
                        element={<JsonFormatter />}
                      />
                      <Route
                        path="tools/image-compressor"
                        element={<ImageCompressor />}
                      />
                      <Route
                        path="tools/file-converter"
                        element={<FileConverter />}
                      />
                    </Routes>
                  </Suspense>
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

          {/* Admin routes */}
          <Route
            path="/users/add"
            element={
              <ProtectedRoute adminOnly>
                <DashboardLayout>
                  <Suspense fallback={<LoadingFallback />}>
                    <AddUser />
                  </Suspense>
                </DashboardLayout>
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/manage"
            element={
              <ProtectedRoute adminOnly>
                <DashboardLayout>
                  <Suspense fallback={<LoadingFallback />}>
                    <ManageUsers />
                  </Suspense>
                </DashboardLayout>
              </ProtectedRoute>
            }
          />

          {/* Home page and case studies */}
          <Route path="/" element={<HomePage />} />
          <Route path="/soophie" element={<Soophie />} />

          <Route path="/cair" element={<CaseStudies.CairCaseStudy />} />
          <Route path="/serpapi" element={<CaseStudies.SerpapiCaseStudy />} />
          <Route
            path="/urban-foody"
            element={<CaseStudies.UrbanFoodyCaseStudy />}
          />
          <Route path="/clomr" element={<CaseStudies.ClomrCaseStudy />} />
          <Route path="/lunchbox" element={<CaseStudies.LunchboxCaseStudy />} />
          <Route path="/vmware" element={<CaseStudies.VmwareCaseStudy />} />
          <Route
            path="/feeling-blessed"
            element={<CaseStudies.FeelingBlessedCaseStudy />}
          />
          <Route
            path="/crunchyroll"
            element={<CaseStudies.CrunchyrollCaseStudy />}
          />
          <Route path="/docker" element={<CaseStudies.DockerCaseStudy />} />
          <Route path="/lottie" element={<CaseStudies.LottieCaseStudy />} />
          <Route path="/video" element={<CaseStudies.VideoCaseStudy />} />
          <Route path="/deck" element={<CaseStudies.Deck />} />
          <Route path="/cv" element={<CaseStudies.CV />} />
          <Route path="/about" element={<CaseStudies.About />} />

          {/* Catch all route */}
          <Route path="*" element={<Navigate to="/dashboard" replace />} />
        </Routes>
      </Suspense>
    </AuthProvider>
  );
};

export default App;
