import React from "react";
import { Box, Paper } from "@mui/material";

const PageLayout = ({ children }) => {
  return (
    <Box sx={{ padding: 3 }}>
      <Paper elevation={2} sx={{ p: 2, mb: 3, borderRadius: 2 }}>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            style={{
              // border: "1px solid rgba(0, 0, 0, 0.1)",
              width: "100%",
              height: "800px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {children}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default PageLayout;
