import React, { useState, useEffect, useCallback, memo } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  IconButton,
  Drawer,
  TextField,
  LinearProgress,
  Alert,
  AppBar,
  Toolbar,
  Snackbar,
  Paper,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import BugReportIcon from "@mui/icons-material/BugReport";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useInView } from "react-intersection-observer";
import styles from "../../../styles/AnimatedBorder.module.css";
import { useAuth } from "../../../context/AuthContext";
import { jiraAPI } from "../../../services/api";

// Memoized JiraBoardCard component
const JiraBoardCard = memo(({ board, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <div
      ref={ref}
      className={`items brand wow fadeInUp cursor-pointer`}
      style={{
        margin: "10px",
        transform: `translateY(${inView ? "0" : "20px"}) ${
          isHovered ? "translateY(-10px)" : ""
        }`,
        opacity: inView ? 1 : 0,
        transition: "transform 0.3s ease, opacity 0.3s ease",
        padding: "0px 0px",
        backgroundColor: "#f4f4f4",
        borderRadius: "20px",
        cursor: "pointer",
        boxShadow: isHovered
          ? "0 15px 30px rgba(0, 0, 0, 0.2)"
          : "0 1px 2px rgba(0, 0, 0, 0.05)",
      }}
      onClick={() => onClick(board)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={`${styles.gradientBorder} item-box no-curve`}
        style={{
          margin: "0",
          height: "220px",
          position: "relative",
          overflow: "hidden",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div style={{ padding: "20px 35px 5px 35px", textAlign: "center" }}>
          <Box
            sx={{
              p: 1.5,
              borderRadius: "12px",
              bgcolor: "primary.lighter",
              color: "primary.main",
              display: "inline-block",
              transition: "transform 0.2s ease",
              transform: isHovered ? "scale(1.1)" : "scale(1)",
            }}
          >
            <BugReportIcon sx={{ fontSize: 40 }} />
          </Box>
        </div>
        <div style={{ padding: "0 20px 20px", textAlign: "center" }}>
          <Typography
            variant="h6"
            component="div"
            fontWeight="600"
            color="#1a1a1a"
            sx={{
              mb: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {board.title}
          </Typography>
          <Typography
            color="text.secondary"
            variant="body2"
            sx={{
              mb: 1,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {board.description || "No description"}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              gap: 4,
              mt: 1,
              mb: 1,
              position: "relative"
            }}
          >
            <Box sx={{ textAlign: "center", minWidth: "60px" }}>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: "block", mb: 0.5 }}
              >
                Status
              </Typography>
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{ color: "primary.main" }}
              >
                {board.status}
              </Typography>
            </Box>
            <Box sx={{ textAlign: "center", minWidth: "60px" }}>
              <Typography
                variant="caption"
                color="text.secondary"
                sx={{ display: "block", mb: 0.5 }}
              >
                Tasks
              </Typography>
              <Typography
                variant="body2"
                fontWeight="medium"
                sx={{ color: "primary.main" }}
              >
                {board.taskCount}
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    </div>
  );
});

JiraBoardCard.displayName = "JiraBoardCard";

const JiraDashboard = () => {
  const { user } = useAuth();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [iframeUrl, setIframeUrl] = useState("");
  const [boardTitle, setBoardTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [boards, setBoards] = useState([]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [iframeError, setIframeError] = useState(false);

  const showSnackbar = useCallback((message, severity = "success") => {
    setSnackbar({ open: true, message, severity });
  }, []);

  const fetchBoards = useCallback(async () => {
    try {
      setLoading(true);
      const result = await jiraAPI.getBoards();
      if (!result.success) throw new Error(result.error);
      setBoards(result.data);
    } catch (err) {
      setError(err.message);
      showSnackbar(err.message, "error");
    } finally {
      setLoading(false);
    }
  }, [showSnackbar]);

  useEffect(() => {
    fetchBoards();
  }, [fetchBoards]);

  const handleDrawerOpen = useCallback(() => {
    setIsDrawerOpen(true);
  }, []);

  const handleDrawerClose = useCallback(() => {
    setIsDrawerOpen(false);
    setIframeUrl("");
    setBoardTitle("");
    setSelectedBoard(null);
  }, []);

  const handleBoardClick = useCallback((board) => {
    setSelectedBoard(board);
    setIsDrawerOpen(true);
  }, []);

  const handleOpenInNewWindow = useCallback((url) => {
    window.open(url, "_blank");
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        setLoading(true);
        const result = await jiraAPI.createBoard({
          title: boardTitle || "New Jira Board",
          url: iframeUrl,
          description: "Jira board integration",
        });

        if (!result.success) throw new Error(result.error);

        setBoards((prevBoards) => [result.data, ...prevBoards]);
        showSnackbar("Board added successfully");
        handleDrawerClose();
      } catch (err) {
        setError(err.message);
        showSnackbar(err.message, "error");
      } finally {
        setLoading(false);
      }
    },
    [iframeUrl, boardTitle, handleDrawerClose, showSnackbar]
  );

  const handleDelete = useCallback(
    async (boardId) => {
      try {
        setLoading(true);
        const result = await jiraAPI.deleteBoard(boardId);

        if (!result.success) throw new Error(result.error);

        setBoards((prevBoards) =>
          prevBoards.filter((board) => board._id !== boardId)
        );
        showSnackbar("Board deleted successfully");
        handleDrawerClose();
      } catch (err) {
        setError(err.message);
        showSnackbar(err.message, "error");
      } finally {
        setLoading(false);
      }
    },
    [handleDrawerClose, showSnackbar]
  );

  const handleSnackbarClose = useCallback(() => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  }, []);

  const handleIframeError = useCallback(() => {
    setIframeError(true);
  }, []);

  return (
    <Box sx={{ padding: 3 }}>
      <Paper elevation={2} sx={{ p: 2, mb: 3, borderRadius: 2 }}>
        <Box
          sx={{
            width: "100%",
            height: "auto",
            minHeight: "800px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            overflow: "hidden",
          }}
        >
          <Box sx={{ width: "100%", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
              }}
            >
              <Typography variant="h4" component="h1" sx={{ fontWeight: 600 }}>
                Jira Boards
              </Typography>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                onClick={handleDrawerOpen}
                sx={{
                  borderRadius: "8px",
                  px: 3,
                  py: 1,
                  background: "linear-gradient(45deg, #2563eb, #3b82f6)",
                  boxShadow: "0 4px 10px rgba(59, 130, 246, 0.3)",
                  "&:hover": {
                    boxShadow: "none",
                    background: "linear-gradient(45deg, #1d4ed8, #1e40af)",
                  },
                }}
              >
                Add Jira
              </Button>
            </Box>

            {error && (
              <Alert severity="error" sx={{ mb: 3 }}>
                {error}
              </Alert>
            )}

            {loading && <LinearProgress sx={{ mb: 3 }} />}

            <Grid container spacing={3}>
              {boards.map((board) => (
                <Grid item xs={12} sm={6} md={4} key={board._id}>
                  <JiraBoardCard board={board} onClick={handleBoardClick} />
                </Grid>
              ))}
              {boards.length === 0 && !loading && (
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 4,
                      textAlign: "center",
                      border: "1px dashed",
                      borderColor: "divider",
                      borderRadius: 2,
                      bgcolor: "background.paper",
                    }}
                  >
                    <BugReportIcon
                      sx={{ fontSize: 60, color: "text.secondary", mb: 2 }}
                    />
                    <Typography variant="h6" color="text.secondary">
                      No Jira boards added yet
                    </Typography>
                    <Typography color="text.secondary" sx={{ mb: 2 }}>
                      Add your first Jira board to get started
                    </Typography>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      onClick={handleDrawerOpen}
                      sx={{
                        mt: 2,
                        borderRadius: "8px",
                        px: 3,
                        py: 1,
                        background: "linear-gradient(45deg, #2563eb, #3b82f6)",
                        boxShadow: "0 4px 10px rgba(59, 130, 246, 0.3)",
                        "&:hover": {
                          boxShadow: "none",
                          background: "linear-gradient(45deg, #1d4ed8, #1e40af)",
                        },
                      }}
                    >
                      Add Jira Board
                    </Button>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Paper>

      {/* Add Board Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen && !selectedBoard}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: { width: { xs: "100%", sm: 400 } },
        }}
      >
        <Box sx={{ p: 3 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
            }}
          >
            <Typography variant="h6" component="h2">
              Add Jira Board
            </Typography>
            <IconButton onClick={handleDrawerClose}>
              <CloseIcon />
            </IconButton>
          </Box>

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Jira Board Title"
              name="boardTitle"
              value={boardTitle}
              onChange={(e) => setBoardTitle(e.target.value)}
              placeholder="My Jira Board"
              sx={{ mb: 3 }}
              required
              helperText="Enter a title for your Jira board"
            />
            <TextField
              fullWidth
              label="Jira Board URL"
              name="iframeUrl"
              value={iframeUrl}
              onChange={(e) => setIframeUrl(e.target.value)}
              placeholder="https://your-domain.atlassian.net/board"
              sx={{ mb: 3 }}
              required
              helperText="Enter the full URL of your Jira board"
            />
            <Button
              type="submit"
              variant="contained"
              fullWidth
              disabled={loading}
              sx={{
                backgroundColor: "#0052CC",
                "&:hover": {
                  backgroundColor: "#0747A6",
                },
              }}
            >
              {loading ? "Adding..." : "Add Board"}
            </Button>
          </form>
        </Box>
      </Drawer>

      {/* Board Preview Drawer */}
      <Drawer
        anchor="right"
        open={isDrawerOpen && selectedBoard}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedBoard(null);
          setIframeError(false);
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: "100%",
            height: "100%",
            position: "fixed",
            right: 0,
            boxSizing: "border-box",
          },
        }}
      >
        {selectedBoard && (
          <>
            <AppBar position="sticky" sx={{ bgcolor: "#0052CC" }}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    setIsDrawerOpen(false);
                    setSelectedBoard(null);
                    setIframeError(false);
                  }}
                  sx={{ mr: 2 }}
                >
                  <ArrowBackIcon />
                </IconButton>
                <Box sx={{ display: "flex", alignItems: "center", flex: 1 }}>
                  <Box
                    sx={{
                      p: 1,
                      borderRadius: "12px",
                      bgcolor: "rgba(255, 255, 255, 0.1)",
                      mr: 2,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <BugReportIcon sx={{ fontSize: 40, color: "white" }} />
                  </Box>
                  <Typography variant="h6" component="div">
                    {selectedBoard.title}
                  </Typography>
                </Box>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => handleOpenInNewWindow(selectedBoard.url)}
                  aria-label="open in new window"
                  sx={{ mr: 1 }}
                >
                  <OpenInNewIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => handleDelete(selectedBoard._id)}
                  aria-label="delete"
                  sx={{ mr: 1 }}
                >
                  <DeleteIcon />
                </IconButton>
                <IconButton
                  edge="end"
                  color="inherit"
                  onClick={() => {
                    setIsDrawerOpen(false);
                    setSelectedBoard(null);
                    setIframeError(false);
                  }}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Box sx={{ height: "calc(100% - 64px)", overflow: "hidden" }}>
              {iframeError ? (
                <Box
                  sx={{
                    p: 3,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <Alert
                    severity="info"
                    sx={{ mb: 3, width: "100%", maxWidth: 600 }}
                  >
                    Due to Jira's security settings, this board cannot be
                    embedded directly. Please use the "Open in new window"
                    button to view the board.
                  </Alert>
                  <Button
                    variant="contained"
                    startIcon={<OpenInNewIcon />}
                    onClick={() => handleOpenInNewWindow(selectedBoard.url)}
                    sx={{
                      backgroundColor: "#0052CC",
                      "&:hover": {
                        backgroundColor: "#0747A6",
                      },
                    }}
                  >
                    Open in Jira
                  </Button>
                </Box>
              ) : (
                <iframe
                  src={selectedBoard.url}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                  }}
                  title={selectedBoard.title}
                  onError={handleIframeError}
                />
              )}
            </Box>
          </>
        )}
      </Drawer>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default JiraDashboard;
