import React from "react";
import { Box, Typography } from "@mui/material";
import PageLayout from "../layout/PageLayout";

const Affine = () => {
  return (
    <PageLayout title="Affine">
      <Box
        sx={{
          display: "flex",
          // justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "calc(85vh)", // Subtract toolbar height
            overflow: "hidden",
            position: "relative",
          }}
        >
          <iframe
            src="https://affine.xdstudios.io"
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              overflow: "hidden",
            }}
            title="Affine"
          />
        </Box>
      </Box>
    </PageLayout>
  );
};

export default Affine;
